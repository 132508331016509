<template>
  <main id="tg-main" class="tg-main tg-haslayout">
    <div class="container">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="book">图书搜索</el-menu-item>
        <el-menu-item index="video">视频搜索</el-menu-item>
        <el-menu-item index="article">资讯搜索</el-menu-item>
      </el-menu>

      <!-- 分类过滤器 -->
      <div
        class="type-container"
        style="border: 1px solid rgba(0,0,0,0.2); margin-top: 10px; padding: 10px 15px 0 15px;"
      >
        <div style="display:flex; border-bottom: 1px dotted rgba(0,0,0,0.2)">
          <div style="width: 100px;"> 一级分类</div>
          <div style="flex:1;">
            <div class="top-types" style="display:flex;">
              <div
                class="classify-item"
                :class="{ active: $route.query.topType === topType._id }"
                style="margin: 0 0 10px 10px"
                v-for="topType of types"
                :key="topType._id"
                @click="onTopTypeChange(topType)"
              >
                {{ topType.name }}
              </div>
            </div>
          </div>
        </div>
        <div
          style="display:flex; margin-top: 8px; border-bottom: 1px dotted rgba(0,0,0,0.2)"
          v-if="secondTypes.length > 0"
        >
          <div style="width: 100px;"> 二级分类</div>
          <div style="flex:1;">
            <div class="second-types" style="display:flex;">
              <div
                class="classify-item"
                :class="{ active: $route.query.secondType === secondType._id }"
                style="margin: 0 0 10px 10px"
                v-for="secondType of secondTypes"
                :key="secondType._id"
                @click="onSecondTypeChange(secondType)"
              >
                {{ secondType.name }}
              </div>
            </div>
          </div>
        </div>
        <div
          style="display:flex; margin-top: 8px; border-bottom: 1px dotted rgba(0,0,0,0.2)"
          v-if="thirdTypes.length > 0"
        >
          <div style="width: 100px;"> 三级分类</div>
          <div style="flex:1;">
            <div class="third-types" style="display:flex;flex-wrap:wrap;">
              <div
                class="classify-item"
                :class="{ active: $route.query.thirdType === thirdType._id }"
                style="margin: 0 0 10px 10px"
                v-for="thirdType of thirdTypes"
                :key="thirdType._id"
                @click="onThirdTypeChange(thirdType)"
              >
                {{ thirdType.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 过滤器 -->
      <div class="filter" style="display:flex;">
        <div
          class="filter-item"
          :class="{ active: filter === 'order' }"
          @click="sort({ key: 'order', order: 'desc' })"
        >
          综合排序
        </div>
        <template v-if="activeIndex === 'book'">
          <div
            class="filter-item"
            :class="{ active: filter === 'sale' }"
            @click="sort({ key: 'sale', order: !filterOrder })"
          >
            销量
          </div>
          <div
            class="filter-item"
            :class="{ active: filter === 'star' }"
            @click="sort({ key: 'star', order: !filterOrder })"
          >
            好评
          </div>
          <div
            class="filter-item"
            :class="{ active: filter === 'publishTime' }"
            @click="sort({ key: 'publishTime', order: !filterOrder })"
          >
            出版时间
          </div>
          <div
            class="filter-item"
            :class="{ active: filter === 'price' }"
            @click="sort({ key: 'price', order: !filterOrder })"
          >
            价格
            <input style="width: 49px;" placeholder="￥" v-model="minPrice" />
            -
            <input style="width: 49px;" placeholder="￥" v-model="maxPrice" />
          </div>
        </template>
        <template v-else>
          <div
            class="filter-item"
            :class="{ active: filter === '_createTime' }"
            @click="sort({ key: '_createTime', order: !filterOrder })"
          >
            发布时间
          </div>
        </template>
      </div>
    </div>

    <div class="tg-sectionspace tg-haslayout">
      <div class="container" style="padding: 0 50px;">
        <div class="row">
          <div v-for="book in [...books]" :key="book._id">
            <!-- 图书列表组件 -->
            <book-item
              v-if="activeIndex === 'book'"
              :key="book._id"
              style="margin-bottom: 29px;"
              :book="book"
            />
            <!-- 视频和资讯共用一个组件 -->
            <video-item
              v-else-if="activeIndex === 'video'"
              style="margin-bottom: 29px;"
              :key="book._id"
              :video="book"
            />
            <video-item v-else style="margin-bottom: 29px;" :key="book._id" :video="book" />
          </div>
        </div>
        <div class="row" style="display:flex; justify-content: center;">
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :total="basicdata.total"
            :current-page="basicdata.page"
            :page-size="basicdata.pagesize"
            @current-change="onPageChange"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Pagination, Menu, MenuItem } from 'element-ui'
import listMixin from '../plugins/mixins/list'
import { CommonQuery, ListQuery } from '../api/commonquery'
import BookItem from '../components/BookItem.vue'
import VideoItem from '../components/VideoItem.vue'
export default {
  mixins: [listMixin],
  components: {
    BookItem,
    VideoItem,
    [Pagination.name]: Pagination,
    [Menu.name]: Menu,
    [MenuItem.name]: MenuItem,
  },
  props: {
    topType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      basicdata: {
        searchkey: '',
        placeholder: '搜索图书、ISBN、作者',
        total: 1,
        pagesize: 5,
        page: 1,
      },
      books: [],
      types: [],
      minPrice: '',
      maxPrice: '',
      filter: 'order',
      filterOrder: 'desc',
      activeIndex: 'book',
    }
  },
  computed: {
    secondTypes() {
      const queryTopType = this.$route.query.topType
      let res = this.types
        .filter((topType) => {
          if (queryTopType) {
            return topType._id === queryTopType
          } else {
            return true
          }
        })
        .reduce((result, type) => {
          for (let key in type) {
            if (Array.isArray(type[key])) {
              // console.log('key', key, type[key])
              result = result.concat(type[key])
              break
            }
          }
          return result
        }, [])
      console.log('secondTypes', res)
      return res
    },
    thirdTypes() {
      const querySecondType = this.$route.query.secondType
      const res = this.secondTypes
        .filter((secondType) => {
          if (querySecondType) {
            return secondType._id === querySecondType
          } else {
            return true
          }
        })
        .reduce((result, type) => {
          for (let key in type) {
            if (Array.isArray(type[key])) {
              result = result.concat(type[key])
              break
            }
          }
          return result
        }, [])
      console.log('thirdTypes', res)
      return res
    },
  },
  watch: {
    activeIndex(nv) {
      console.log('model change', nv)
      this.getTypes()
      this.onPageChange(1)
    },
    $route: {
      deep: true,
      handler() {
        this.onPageChange(1)
      },
    },
  },
  methods: {
    async getData(newQuery = {}) {
      this.initbasicData(newQuery)
      // 基础请求内容
      let data = {
        model: this.activeIndex,
        query: {},
        skip: (this.basicdata.page - 1) * this.basicdata.pagesize,
        limit: this.basicdata.pagesize,
      }

      // 关键字模糊匹配
      if (newQuery.keyword) {
        Object.assign(data, {
          searchKey: newQuery.keyword,
        })
      }

      // 一级分类筛选
      if (newQuery.topType) {
        Object.assign(data.query, {
          topType: {
            $eq: newQuery.topType,
          },
        })
      }

      // 二级分类筛选
      if (newQuery.secondType) {
        Object.assign(data.query, {
          secondType: {
            $eq: newQuery.secondType,
          },
        })
      }

      // 三级分类筛选
      if (newQuery.thirdType) {
        Object.assign(data.query, {
          thirdType: {
            $eq: newQuery.thirdType,
          },
        })
      }

      // 图书仅有的价格区间筛选
      if (this.activeIndex === 'book' && this.minPrice && this.maxPrice) {
        Object.assign(data.query, {
          $and: [
            {
              price: {
                $gt: this.minPrice,
              },
            },
            {
              price: {
                $lt: this.maxPrice,
              },
            },
          ],
        })
      }

      // 排序
      if (newQuery.sort) {
        Object.assign(data, {
          sort: JSON.parse(newQuery.sort),
        })
      }

      let res = await CommonQuery(data)
      this.books = res.data.map((book) => {
        return {
          ...book,
          cover: book.cover,
          readed: book.readed || 0,
          to: {
            name: `${this.activeIndex}Detail`,
            query: {
              id: book._id,
            },
          },
        }
      })
      this.basicdata.total = res.total
    },

    async getTypes() {
      let query = {}
      let model = ''
      switch (this.activeIndex) {
        case 'book':
          model = 'bookTopType'
          query = '287a53aa61aced8100930cf21e1f50fb'
          break
        case 'video':
          model = 'videoTopType'
          query = '0448022461aced8b00a5762131dadaee'
          break
        case 'article':
          model = 'articletype'
          query = '381d149061aceda200b7aff722a7d2de'
          break
      }
      let data = {
        model,
        query,
      }
      let res = await ListQuery(data)
      this.types = res.data
      console.log(this.types)
    },

    /**
     * 处理tab选择
     */
    handleSelect(index) {
      this.activeIndex = index
      // eslint-disable-next-line no-unused-vars
      let { topType, secondType, thirdType, ...query } = { ...this.$route.query }
      this.changeSelectUrl(query)
    },

    onTopTypeChange(item) {
      // eslint-disable-next-line no-unused-vars
      let { topType, secondType, thirdType, ...query } = { ...this.$route.query }
      Object.assign(query, {
        topType: item._id,
      })
      this.changeSelectUrl(query)
    },

    onSecondTypeChange(item) {
      // eslint-disable-next-line no-unused-vars
      let { secondType, thirdType, ...query } = { ...this.$route.query }
      Object.assign(query, {
        secondType: item._id,
      })
      this.changeSelectUrl(query)
    },

    onThirdTypeChange(item) {
      // eslint-disable-next-line no-unused-vars
      let { thirdType, ...query } = { ...this.$route.query }
      Object.assign(query, {
        thirdType: item._id,
      })
      this.changeSelectUrl(query)
    },

    sort(orderOpt) {
      const { key, order } = orderOpt
      this.filter = key
      this.filterOrder = order
      this.onSortChange()
    },

    onSortChange() {
      let query = { ...this.$route.query }
      Object.assign(query, {
        sort: JSON.stringify({
          key: this.filter,
          order: this.filterOrder ? 'asc' : 'desc',
        }),
      })
      this.changeSelectUrl(query)
    },

    onPageChange(newPage) {
      let query = { ...this.$route.query }
      Object.assign(query, {
        page: newPage,
      })
      this.changeSelectUrl(query)
    },
  },
  activated() {
    if (this.$route.params.defaultActive) {
      this.activeIndex = this.$route.params.defaultActive
      console.log('activated active', this.activeIndex)
      this.onPageChange(1)
    }
  },
  created() {
    if (this.$route.params.defaultActive) {
      this.activeIndex = this.$route.params.defaultActive
      console.log('active', this.activeIndex)
    }
    this.getTypes()
    this.getData(this.$route.query)
  },
}
</script>

<style scoped>
.classify-item {
  cursor: pointer;
}

.classify-item.active {
  color: #f05000;
}

.filter {
  margin-top: 10px;
  background-color: rgba(16, 16, 16, 0.05);
}

.filter-item {
  font-size: 14px;
  border-right: 1px dotted rgba(0, 0, 0, 0.6);
  padding: 7px 20px;
  cursor: pointer;
}

.filter-item.active {
  color: #f05000;
}

.container >>> .el-menu.el-menu--horizontal {
  border-bottom: 1px solid #f05000;
}

.container >>> .el-menu-item {
  font-size: 16px;
}

.container >>> .el-menu-item.is-active {
  border-width: 5px;
  color: #f05000;
}
</style>
