<template>
  <div class="item">
    <div class="postbook" style="display:flex;">
      <div class="bookimg" style="width: 200px; height:200px;">
        <div class="frontcover">
          <img :src="book.coverUrl" style="width: 200px; height:200px;object-contain:cover;" />
        </div>
      </div>
      <div class="postbookcontent" style="flex:1;margin-left:30px;">
        <div class="booktitle">
          <router-link tag="a" target="_blank" :to="book.to">{{ book.title }}</router-link>
        </div>
        <div class="bookprice"
          >￥{{ book.price | money }}<span class="bookdiscount">{{ book.discount }}</span></div
        >
        <div class="bookpublish">{{ book.author }}/{{ book.publishDate }}/{{ book.press }}</div>
        <div class="bookstars">
          <i
            v-for="s in 5"
            :key="s"
            :class="`el-icon-star${s <= book.star ? '-on' : '-off'}`"
            style="font-size:16px;color: rgba(255, 150, 0, 100)"
          />
        </div>
        <div class="bookdesc">{{ book.desc }}</div>
        <div class="buttons" style="margin-top:23px;display:flex;align-items:center">
          <el-button type="primary" size="small" @click="AddFavorite">
            <div style="display:flex; align-items: center;">
              <img
                src="/images/icons/collect.png"
                style="width:24px;height:24px;"
                @click="AddFavorite"
              />
              <span style="margin-left: 5px;font-size:16px;">收藏</span>
            </div>
          </el-button>
          <el-popover placement="top" width="160" style="margin-left: 20px;">
            <!-- 购买按钮弹窗 -->
            <div style="text-align: right; margin: 0">
              <template v-if="!book.jdUrl && !book.tbUrl">
                该书暂无购买链接
              </template>
              <template v-else>
                <el-button
                  type="primary"
                  size="mini"
                  :disabled="!book.jdUrl"
                  @click="
                    () => {
                      window.open(book.jdUrl)
                    }
                  "
                  >京东购买地址</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  :disabled="!book.tbUrl"
                  @click="
                    () => {
                      window.open(book.tbUrl)
                    }
                  "
                  >淘宝购买地址</el-button
                >
              </template>
            </div>
            <!-- 购买按钮插槽 -->
            <el-button slot="reference" plain type="primary" size="small">
              <div style="display:flex;align-items:center;">
                <img src="/images/icons/shopping.png" style="width:24px;height:24px;" />
                <span style="font-size:16px;margin-left:5px;">购买</span>
              </div>
            </el-button>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Popover } from 'element-ui'

export default {
  name: 'Book',
  components: { [Popover.name]: Popover },
  props: ['book'],
  methods: {
    AddFavorite() {},
  },
  created() {
    console.log(this.book)
  },
}
</script>

<style scoped>
.booktitle {
  font-size: 18px;
  /* margin-top: 10px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bookprice {
  margin-top: 5px;
  font-size: 20px;
  color: #f05000;
}

.bookdiscount {
  font-size: 13px;
  color: rgba(16, 16, 16, 0.4);
}

.bookpublish {
  font-size: 14px;
  margin-top: 9px;
}

.bookdesc {
  width: 700px;
  font-size: 14px;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.4);
}

.bookstars {
  display: flex;
}
</style>
