<template>
  <div class="item">
    <div class="postbook" style="display:flex;">
      <div class="bookimg" style="width: 200px; height:113px;">
        <div class="frontcover">
          <img :src="video.cover" style="width: 200px; height:113px;object-contain:cover;" />
        </div>
      </div>
      <div class="postbookcontent" style="flex:1;margin-left:30px;">
        <div class="booktitle">
          <router-link tag="a" target="_blank" :to="video.to">{{ video.title }}</router-link>
        </div>
        <div class="bookpublish">
          <div class="">发布时间：{{ moment(video._createTime).format('YYYY-MM-DD') }}</div>
          <div class="" style="margin-left: 50px;">上传者: {{ video.author }}</div>
        </div>
        <div class="bookdesc">简介：{{ video.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Book',
  props: ['video'],
  methods: {
    moment,

    AddFavorite() {},
  },
  created() {
    console.log('video', this.video)
  },
}
</script>

<style scoped>
.booktitle {
  font-size: 18px;
  /* margin-top: 10px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bookprice {
  margin-top: 5px;
  font-size: 20px;
  color: #f05000;
}

.bookdiscount {
  font-size: 13px;
  color: rgba(16, 16, 16, 0.4);
}

.bookpublish {
  font-size: 14px;
  margin-top: 9px;
  display: flex;
}

.bookpublish > div {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}

.bookdesc {
  width: 700px;
  font-size: 14px;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.4);
}
</style>
